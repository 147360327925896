<template>
  <DialogBase
    :dialogWidth="700"
    :dialogModel="dialogModel"
    @closeDialog="(val) => closeDialog(val)"
    scrollable
  >
    <template v-slot:title>
      {{ !!selectedModel ? `Update` : `Create` }} Landing Page
    </template>

    <v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
      <FormMaker :schema="form_structure" :model="form_data"></FormMaker>
      <ImageUploadBtn
        :btnText="`${!!selectedModel ? 'Change' : 'Upload'} Company Logo Image`"
        :previewImageSrc="
          !!selectedModel ? selectedModel.company_logo_image_url : null
        "
        @done="(image) => (editedCompanyLogoImage = image)"
        :imageWidth="145"
        :imageHeight="40"
      ></ImageUploadBtn>
    </v-form>

    <template v-slot:footer>
      <v-btn color="accent" outlined block @click="submit()">
        {{ selectedModel ? `Update` : `Create` }}
      </v-btn>
    </template>
  </DialogBase>
</template>

<script>
import {
  required,
  minLen,
  validDomainName,
} from "@/plugins/validationRules.js";
import FormMaker from "@/components/FormMaker";
import DialogBase from "@/components/dialogs/Base";
import ImageUploadBtn from "@/components/btns/ImageUploadBtn";

export default {
  components: { DialogBase, FormMaker, ImageUploadBtn },
  props: {
    dialogModel: { required: true, type: Boolean },
    selectedModel: { required: true, default: null },
  },
  data() {
    return {
      isValidForm: false,
      editedCompanyLogoImage: null,
      form_data: {
        is_active: "Yes",
        company_name: null,
        domain: null,
        source: null,
        company_logo_image: null,
        payload: {
          phone_number: null,
          phone_number2: null,
        },
      },
    };
  },
  mounted() {
    if (this.selectedModel) {
      this.form_data = Object.assign({}, this.selectedModel);
    } else {
    }
  },
  watch: {
    selectedModel(newVal, oldVal) {
      if (newVal) this.form_data = Object.assign({}, this.selectedModel);
    },
  },
  computed: {
    form_structure() {
      let form = {
        is_active: {
          type: "btn-toggle",
          name: "is_active",
          label: "Is active",
          ...this.$helpers.formFieldsBasicLayout,
          class: "custom",
          options: ["Yes", "No"],
          mandatory: true,
          rules: [required],
        },
        company_name: {
          type: "text",
          name: "company_name",
          label: `Company Name`,
          ...this.$helpers.formFieldsBasicLayout,
          rules: [required()],
        },
        domain: {
          type: "text",
          name: "domain",
          label: `Domain`,
          prefix: "[3.131.249.31] = ",
          ...this.$helpers.formFieldsBasicLayout,
          rules: [required(), validDomainName()],
        },
        source: {
          type: "text",
          name: "source",
          label: `Source`,
          ...this.$helpers.formFieldsBasicLayout,
          rules: [required()],
        },
        payload: {
          phone_number: {
            type: "text",
            name: "phone_number",
            label: `Company Phone Number`,
            ...this.$helpers.formFieldsBasicLayout,
            mask: "(###) ###-####",
            rules: [required(), minLen(14, "10 Digits required.")],
          },
          phone_number2: {
            type: "text",
            name: "phone_number2",
            label: `Company Phone Number2`,
            ...this.$helpers.formFieldsBasicLayout,
            mask: "(###) ###-####",
            rules: [required(), minLen(14, "10 Digits required.")],
          },
        },
      };
      return form;
    },
  },
  methods: {
    closeDialog(val = false) {
      this.$emit("closeDialog", val);
    },
    refresh(val = false) {
      this.$emit("refresh");
    },
    async submit() {
      this.$refs.from.validate();
      if (!this.isValidForm) return;

      let data = {
        ...this.form_data,
        session_key: this.sessionKey,
        company_logo_image: this.editedCompanyLogoImage?.imageFile,
      };

      data = this.$helpers.jsonToFormData(data);

      return await this.post(
        `${this.baseUrl}/agent-portal/create-update-landing-page`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          this.refresh();
          this.closeDialog();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
